import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useViewer } from "core/components/ViewerProvider";
import { useArena } from "core/components/ArenaProvider";
import BanScreen from "core/components/BanScreen";
import Page from "arena/components/Page";
import Unsubscribe from "arena/components/Unsubscribe";
import DisableSubscription from "arena/components/DisableSubscription";
import InvalidToken from "arena/components/InvalidToken";
import AppShell from "arena/components/AppShell";
import Search from "arena/components/Search";
import Contents from "arena/components/Contents";
import Favorites from "arena/components/Favorites";
import Video from "arena/components/Video";
import Post from "arena/components/Post";
import Tag from "arena/components/Tag";
import Purchases from "arena/components/ProductPurchases";
import Stream from "arena/components/Stream";
import Collection from "arena/components/Collection";
import Product from "arena/components/Product";
import InvalidOauth from "arena/components/InvalidOauth";
import SuccessAuth from "arena/components/SuccessAuth";
import AuthRequired from "arena/components/AuthRequired";
import useOauthResult from "arena/hooks/useOauthResult";
import trackEvent from "arena/utils/trackEvent";

export default function AppRoutes() {
  const viewer = useViewer();
  const arena = useArena();
  const successAuth = useOauthResult();

  useState(() => {
    trackEvent("pageView", { arena });
  }, []);

  if (viewer?.isBanned) {
    return <BanScreen />;
  }

  if (successAuth) {
    return <SuccessAuth />;
  }

  return (
    <AppShell arena={arena}>
      <Routes>
        <Route path="/tag/:tagId" element={<Tag />} />
        <Route path="/post/:postId" element={<Post />} />
        <Route path="/video/:contentId" element={<Video />} />
        <Route path="/stream/:contentId" element={<Stream />} />
        <Route path="/collection/:contentId" element={<Collection />} />
        <Route path="/product/:productId" element={<Product />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/contents" element={<Contents />} />
        <Route path="/search" element={<Search />} />
        <Route path="/invalidOauth" element={<InvalidOauth />} />
        <Route path="/unsubscribe/:token" element={<Unsubscribe />} />

        <Route
          path="/disableSubscription/:token"
          element={<DisableSubscription />}
        />
        <Route path="/invalidToken" element={<InvalidToken />} />

        <Route
          path="/purchases"
          element={viewer ? <Purchases /> : <AuthRequired />}
        />

        <Route
          path="/tv"
          element={<Navigate to="/?addDevice=" />}
          replace={true}
        />

        <Route path="*" element={<Page />} />
      </Routes>
    </AppShell>
  );
}
